<template>
    <div class="company-info-setup py-20">
        <div class="row col-12 mx-0">
            <div class="col-md-4 ps-0">
                <h2>{{ $t("Get started") }}</h2>
                <p>{{ $t("By entering your company details") }}</p>
            </div>
            <div class="col-md-4 text-center">
                <img class="w-50" src="/media/logos/buying/blue-logo-buying-teams.svg" alt="">
            </div>
            <div class="col-md-4 d-flex justify-content-end align-items-center">
                <p class="mb-0 me-3">{{ $t("Existing User ?") }}</p>
                <router-link to="/sign-in">{{ $t("Log in") }}</router-link>
            </div>
        </div>

        <el-form
            class="setupForm"
            ref="companySetupFormRef"
            :model="companySetupForm"
            :rules="companySetupFormRules"
        >
            <el-form-item prop="company_name">
                <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Group Company Name") }}</label>
                <el-input
                    :placeholder="$t('Enter your Company Name (Group level)')"
                    v-model="companySetupForm.company_name"></el-input>
            </el-form-item>
            <el-form-item prop="industries">
                <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Industry") }}</label>
                <el-select
                    multiple
                    v-model="companySetupForm.industries"
                    :placeholder="$t('Select Industry')">
                    <el-option
                        v-for="(industry, index) in industries"
                        :key="index"
                        :label="industry"
                        :value="industry"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="num_employees">
                <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Number of Employees") }}</label>
                <el-select
                    v-model="companySetupForm.num_employees"
                    :placeholder="$t('Select Number of Employees')">
                    <el-option
                        v-for="(employeesNumber, index) in employeesNumbers"
                        :key="index"
                        :label="employeesNumber"
                        :value="employeesNumber"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="revenue">
                <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Global revenue") }}</label>
                <el-select
                    v-model="companySetupForm.revenue"
                    :placeholder="$t('Select Global revenue')">
                    <el-option
                        v-for="(globalRevenue, index) in globalRevenues"
                        :key="index"
                        :label="globalRevenue.label"
                        :value="globalRevenue.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="country">
                <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Country of Global headquarter")
                    }}</label>
                <el-select
                    filterable
                    v-model="companySetupForm.country"
                    :placeholder="$t('For eg. `Denmark`')">
                    <el-option
                        v-for="(country, index) in countries"
                        :key="index"
                        :label="country"
                        :value="country"
                    ></el-option>
                </el-select>
            </el-form-item>
            <!--      <el-form-item prop="companyLogo">-->
            <!--        <div class="position-relative" v-click-outside="onClickOutside">-->
            <!--          <label class="form-label auth-label fs-6 fw-bolder">{{ $t("Company Logo") }}</label>-->
            <!--          <div class="uploadFile" @click="showDropdown = true">-->
            <!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--              <rect x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)"-->
            <!--                    fill="#435BF4" />-->
            <!--              <path-->
            <!--                d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457-->
            <!--              7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026-->
            <!--              3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458-->
            <!--              9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"-->
            <!--                fill="#435BF4" />-->
            <!--              <path-->
            <!--                d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25-->
            <!--              17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772-->
            <!--              19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75-->
            <!--              8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75-->
            <!--              21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"-->
            <!--                fill="#435BF4" />-->
            <!--            </svg>-->
            <!--            <h4>{{ $t("Upload Company Logo") }}</h4>-->
            <!--          </div>-->
            <!--          <div class="dropdown-list" v-if="showDropdown">-->
            <!--            <p @click="changeRouter">-->
            <!--              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path fill-rule="evenodd" clip-rule="evenodd"-->
            <!--                      d="M6.94071 0.83863C6.84807 0.882072 6.73505 0.97235 6.68955 1.03921C6.6441 1.10611 5.12058 3.88127 3.30403 7.20624C0.0477642 13.1664 0.0011163 13.255 4.77167e-05 13.485C-0.00102087 13.718 0.00148629 13.7226 1.67995 16.5819C2.60452 18.1569 3.40826 19.4985 3.46608 19.5631C3.52391 19.6278 3.63673 19.7003 3.71675 19.7241C3.81403 19.7531 6.09135 19.7633 10.5821 19.755L17.3018 19.7425L17.4337 19.6454C17.5249 19.5783 18.0952 18.6755 19.2828 16.7182L21 13.888L20.999 13.6547L20.998 13.4213L17.3004 7.18503C13.695 1.10403 13.5983 0.946344 13.4195 0.853266L13.2361 0.757812L10.1726 0.758746C7.27399 0.759637 7.10014 0.763921 6.94071 0.83863ZM15.9478 7.51382C17.7047 10.483 19.15 12.9266 19.1594 12.9441C19.1689 12.9616 18.0499 12.9759 16.6728 12.9759H14.1688L11.2994 7.64109C9.72117 4.70697 8.40344 2.26331 8.37105 2.21083L8.31216 2.11538H10.5328H12.7534L15.9478 7.51382ZM8.47836 5.19332C9.16362 6.4659 9.71846 7.53227 9.71135 7.56303C9.69219 7.64584 3.9488 17.723 3.91937 17.7253C3.89381 17.7274 1.48119 13.6391 1.43557 13.5165C1.41983 13.4741 2.47111 11.5069 4.29576 8.16426C5.88252 5.25738 7.19245 2.87913 7.20667 2.87926C7.22085 2.87939 7.79315 3.92073 8.47836 5.19332ZM11.5656 10.9279C12.1333 11.9832 12.6086 12.8757 12.6218 12.9112C12.6432 12.9687 12.3969 12.9759 10.4177 12.9759C8.51186 12.9759 8.19276 12.9672 8.21188 12.9158C8.23 12.867 10.0874 9.59144 10.3867 9.0804C10.4321 9.00285 10.4836 8.95513 10.5013 8.97434C10.519 8.99356 10.9979 9.87267 11.5656 10.9279ZM18.9857 14.641C18.8841 14.8102 18.3325 15.7218 17.7599 16.6668L16.7188 18.3849L10.8944 18.3957L5.06995 18.4064L5.17492 18.226C5.23266 18.1267 5.75573 17.2103 6.33733 16.1895L7.39478 14.3335H13.2826H19.1705L18.9857 14.641Z"-->
            <!--                      fill="#454545" fill-opacity="0.8" />-->
            <!--              </svg>-->
            <!--              {{ $t("Google Drive") }}-->
            <!--            </p>-->
            <!--            <input type="file">-->
            <!--            <p class="cursor-pointer">-->
            <!--              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path-->
            <!--                  d="M15.1253 5.50033V16.042C15.1253 18.0678 13.4845 19.7087 11.4587 19.7087C9.43283 19.7087 7.79199 18.0678 7.79199 16.042V4.58366C7.79199 3.31866 8.81866 2.29199 10.0837 2.29199C11.3487 2.29199 12.3753 3.31866 12.3753 4.58366V14.2087C12.3753 14.7128 11.9628 15.1253 11.4587 15.1253C10.9545 15.1253 10.542 14.7128 10.542 14.2087V5.50033H9.16699V14.2087C9.16699 15.4737 10.1937 16.5003 11.4587 16.5003C12.7237 16.5003 13.7503 15.4737 13.7503 14.2087V4.58366C13.7503 2.55783 12.1095 0.916992 10.0837 0.916992C8.05783 0.916992 6.41699 2.55783 6.41699 4.58366V16.042C6.41699 18.8287 8.67199 21.0837 11.4587 21.0837C14.2453 21.0837 16.5003 18.8287 16.5003 16.042V5.50033H15.1253Z"-->
            <!--                  fill="#454545" fill-opacity="0.8" />-->
            <!--              </svg>-->
            <!--              {{ $t("Files") }}-->
            <!--            </p>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </el-form-item>-->
        </el-form>

        <button
            :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'"
            id="kt_sign_in_submit"
            class="btn btn-lg main-btn w-100 my-5 mt-10 d-block m-auto"
            @click="saveToContinue()">
            <span class="indicator-label"> {{ $t("continue") }} </span>

            <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
        </button>

        <div class="d-flex justify-content-center align-items-center links-wrapper">
            <a :href="platformConfigs.platformLinks.Terms" target="_blank">
                {{ $t("Terms & Conditions") }}
            </a>
            <span></span>
            <a :href="platformConfigs.platformLinks.Privacy" target="_blank">
                {{ $t("Privacy Policy") }}
            </a>
        </div>

    </div>
</template>

<script>
import { defineComponent } from "vue";
import vClickOutside from "click-outside-vue3";
import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";

export default defineComponent({
    name: "CompanySetup",
    components: {},
    directives: {
        clickOutside: vClickOutside.directive
    },

    data() {
        let buttonIndicator = "off";
        const companySetupForm = {
            company_name: "",
            industries: [],
            num_employees: "",
            revenue: "",
            country: ""
            // companyLogo: {}
        };

        const companySetupFormRules = {
            company_name: [
                { required: true, message: this.$t("Please input Group Company Name"), trigger: "change" },
                { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
            ],
            industries: [
                { required: true, message: this.$t("Please select Industry"), trigger: "change" }
            ],
            num_employees: [
                { required: true, message: this.$t("Please select Number of Employees"), trigger: "change" }
            ],
            revenue: [
                { required: true, message: this.$t("Please select Global revenue"), trigger: "change" }
            ],
            country: [
                { required: true, message: this.$t("Please input Country of Global headquarter"), trigger: "change" }
            ]
            // companyLogo: [
            //   { required: true, message: this.$t("Please upload company logo"), trigger: "change" }
            // ]
        };
        let showDropdown = false;

        return {
            companySetupForm,
            companySetupFormRules,
            showDropdown,
            buttonIndicator,
            platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS),
        };
    },

    mounted() {
        store.dispatch("fetchStaticData", StaticDataEnum.COUNTRIES);
        store.dispatch("fetchStaticData", StaticDataEnum.INDUSTRIES);
        store.dispatch("fetchStaticData", StaticDataEnum.EMPLOYEE_COUNTS);
        store.dispatch("fetchStaticData", StaticDataEnum.REVENUES);
    },

    computed: {
        industries() {
            return store.getters.allIndustries;
        },
        employeesNumbers() {
            return store.getters.employeesCounts;
        },
        globalRevenues() {
            return store.getters.globalRevenues;
        },
        countries() {
            return store.getters.allCountries;
        }
    },

    methods: {
        onClickOutside(event) {
            this.showDropdown = false;
        },

        async saveToContinue() {
            await this.$refs["companySetupFormRef"].validate(async (valid) => {
                if (valid) {
                    this.buttonIndicator = "on";

                    await store.dispatch("createBusiness", this.companySetupForm)
                        .then(res => {
                            diagLog("createBusiness", res);
                            if (res.status === 200) {
                                redirectToRouteWithName("business-register/personal-info");
                            }
                        })
                        .catch(error => {
                            NotificationService.swalError(error);
                        })
                        .finally(_ => {
                            this.buttonIndicator = "off";
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
});
</script>
<style lang="scss">
.company-info-setup {
    .dropdown-list {
        background: #FFFFFF;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        width: auto;
        padding: 20px;
        display: inline-block;
        position: absolute;
        margin-top: 5px;
        left: 20%;

        input {
            position: absolute;
            width: 90px;
            opacity: 0;
            z-index: 9;
            cursor: pointer;
        }

        &::after {
            content: '';
            width: 10px;
            height: 10px;
            border-bottom: 8px solid #fff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: - 8px;
            left: calc(50% - 4px);
        }

        &::before {
            content: '';
            width: 10px;
            height: 10px;
            border-bottom: 8px solid #bfc4e357;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: - 9px;
            left: calc(50% - 4px);
        }
    }

    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: #000000;
    }

    p {
        font-size: 16px;
        line-height: 29px;
        color: #434343;
        opacity: 0.6
    }

    .uploadFile {
        background: #F4F5FE;
        border: 1px solid rgba(67, 91, 244, 0.3);
        box-sizing: border-box;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;

        h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #435BF4;
            margin-left: 20px;
            margin-bottom: 0;
        }
    }

    .setupForm {
        .el-form-item .el-input .el-input__inner {
            border: 1px solid #fff;
        }

        .el-form-item.is-error .el-input__inner {
            border-color: #f56c6c;
        }
    }

    .links-wrapper {
        margin-top: 28px;

        a {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #435BF4;
        }

        span {
            display: block;
            width: 1px;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.1);
            margin: 0 20px;
        }
    }
}
</style>
